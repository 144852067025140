<template>


    <div>
        <div class="portfolio" >
            <div class="grid">
                <div class="col-4">
                    <breadcrumb  :home="home" :model="britems" />
                    <lottie-player
                                autoplay
                                loop
                                mode="normal"
                                speed="1.5"
                                src="https://assets5.lottiefiles.com/packages/lf20_yus0g1kr.json"
                                >
                            </lottie-player>
                </div>
                <div class="col-7">
                    <div class="grid">
                        <div class="col-12 h-full w-full">
                            <div >
                                <h6 style="text-align: center;" class="text-3xl">Important Points to consider</h6>                        
                            </div>                                             
                        </div>
                        <TransitionGroup name="list">
                            <div class="cardpadd card col-12 h-full w-full" v-for="item in items" :key="item">
                            <div class="grid">
                                <div class="col-1">
                                    <i class="pi pi-check-circle" style="font-size: 2rem;color: tomato;"></i>
                                </div>
                                <div class="col-11 points">{{ item }}</div>                        
                            </div>
                                
                            </div>
                        </TransitionGroup>
                        <div v-if="this.items.length==this.db_items.length" class="col-12">
                            <div style="text-align: center">                        
                                <Button label="Next" style="width:60%" class="p-button-raised p-button-rounded  p-3 text-xl" @click="manageEvent"></button>
                            </div>                                             
                        </div>
                        
                    </div>
                        
                </div>
                <div class="col-3">
                     &nbsp;
                </div>
             
               
              
               
            </div>
        </div>
    </div>
</template>

<script>
import "@lottiefiles/lottie-player";
export default {
	data() {
		return {
            home: {icon: 'pi pi-home', to: '/'},
            britems: [
				{label: 'Points',disabled:true},
            ],	
			db_items:[
                    "It is advisable to utilize SpeedNetworking for larger group, 16 or more participants",
                    "Duration of the event will not exceed 60 minutes",
                    "There will be 1 : 1 connects of 15 minutes each",
                    "You will receive an auto MS Outlook notification 2 days prior to the event as a reminder to create/review and share schedule",
                    'You will be responsible to create and share "Roster" under "Manage Event" option (Home page) at least 2 days prior to the event',
                    'You can review and “Shuffle” the Roster, if required under “Manage Event” option',
                    'You can “Cancel” the event  by clicking on “Cancel” under “Manage Event” option'
                ],
            items:[],
            i:0,
		}
	},
	mounted() {
        
        this.myTimeout = setInterval(this.addItemList,200);
        
    },
	methods: {		
		manageEvent() {
            this.$router.push('/create/event');
        },
        addItemList(){
            this.items.push(this.db_items[this.i]);
            this.i=this.i+1;
            if(this.i>=this.db_items.length){
                clearInterval(this.myTimeout);
            }
        }
		
	}
}
</script>

<style lang="scss" scoped>

.list-enter-active,
.list-leave-active {
  transition: all 1.7s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.points {
    font-size: small;
    text-align: initial;
    padding-top: 1rem;
}
.gaurav_landing::before {
    display: block;
    content: "";
    width: 600px;
    height: 600px;
    position: absolute;
    bottom: 1px;
    right: 5px;
    border-bottom: 5px solid orangered;
    border-right: 5px solid red;
}
.snimg {
    width: 100%; /* or any custom size */
    height: 100%; 
    object-fit: contain;
}
.no-r-pad {
    padding-right: 0rem;
}
.model-info {
    border-color: #0093bc;
    border-width: 1px;
    border-style: solid;
    min-height: 16rem;
    position: relative;
    div {
        min-height: 10rem;
    }
    button {
        display: block;
        margin: 0 auto;

        margin-top: 1rem;
        padding: 0.2rem 2rem 0.2rem 2rem;
    }
    a {
        bottom: 0.8rem;
    }
    h6 {
        color: #0093bc;
        font-weight: 600;
    }
}

div {
    text-align: justify;
    text-justify: inter-word;
}
.contact-box-container {
    padding-bottom: 0rem !important;
    padding-top: 0rem;
    h2,
    h3,
    h4 {
        display: block;
    }
    .contact-box {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: #0093bc;
        height: 20rem;
        color: #fff;
        display: flex;
        justify-content: center;
        margin: 0rem !important;
        h4,
        div {
            display: block;
            margin: 0 auto;
            text-align: center;
            justify-content: center;
        }
        div {
            font-size: 92%;
        }
        button {
            background-color: #efefef;
            color: #2c2c2c;
            padding: 0.2rem 2rem 0.2rem 2rem;
        }
    }
}

.cardpadd{
    padding: 0.3rem !important;
}
.portfolio {
    padding-left: 1rem;

    .formulator-desc {
        h5 {
            color: #0093bc;
            font-weight: 600;
        }
    }

    .formulator-marketing {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        small {
            font-size: 40%;
        }
    }
    h5 {
        height: 10px;
    }
    .p-grid {
        .col-12 {
            .overview-box {
                padding: 0px;
                .p-button-info {
                    float: right;
                }
                .overview-box-value {
                    background: #0093bc;
                    color: #fff;
                    font-size: 14px;
                    padding: 0.3rem 0.2rem;

                    border-radius: 5px 5px 0px 0px;
                }
                .overview-box-title {
                    padding: 0.8rem 0.8rem;
                    font-size: 98%;
                    border-radius: 0px 0px 5px 5px;
                    background: #eeeeee;
                    font-weight: 300;
                }
            }
        }
    }
}
</style>
